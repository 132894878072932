import React, { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { Toggle } from "@radix-ui/react-toggle";
import { Share, Copy, Share2, Shield, ExternalLink, Trash } from "lucide-react";
import { Tooltip } from "@/components/feedback/Tooltip";
import { If } from "react-haiku";
import * as Toast from "@radix-ui/react-toast";
import { ShieldOff } from "react-feather";
import { Button } from "@/components/actions/Button";

const DeleteConfirm = ({ id = "", onDelete = () => {} }) => {
  const [isDeleted, setIsDeleted] = useState(false);

  const deleteChallenge = async () => {
    onDelete("deleting");
    await fetch(`/app/api/assistant/challenge/${id}`, {
      method: "DELETE",
    });
    setIsDeleted(true);
    onDelete("done");
  };

  return (
    <>
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-[#836DDB]">
            <Tooltip text="Delete this session.">
              <Trash className="w-5 h-5 text-white" />
            </Tooltip>
          </button>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="w-[320px] max-w-full bg-white text-black border-black border-2 rounded-md shadow-lg px-0 py-4 z-[53] stack gap-4"
            sideOffset={5}
            side="top"
          >
            <div className="stack gap-4 px-6">
              <h2 className="text-2xl">Are you sure you want to do that?</h2>

              <p className="text-[#5b5b5b]">
                This is permanent and cannot be undone.
              </p>
            </div>

            <div className="flex justify-between items-center pt-4 border-t-black border-t-2 gap-6  px-6">
              <Button size="small" color="purple" onClick={deleteChallenge}>
                Delete It
              </Button>
              <Popover.Close className="text-purple" aria-label="Close">
                Cancel
              </Popover.Close>
            </div>

            <Popover.Arrow className="fill-purple" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};

export default DeleteConfirm;
