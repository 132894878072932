"use client";

import FormattedDate from "@/components/components/FormattedDate";
import { Idea, Tag } from "@prisma/client";
import clsx from "clsx";
import { Eye, Hash, X } from "lucide-react";
import Link from "next/link";
import { useState } from "react";
import If from "@/components/common/If";

export default function IdeaCard({ idea }: { idea: Idea }) {
  const [showTags, setShowTags] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState<Tag[]>(idea?.tags || []);

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const response = await fetch(`/app/api/ideas/${idea.id}`, {
        method: "POST",
        body: JSON.stringify({ tag: newTag }),
      });

      if (response.ok) {
        const newTags = await response.json();
        setShowTags(false);
        setNewTag("");
        setTags([...newTags.newTags]);
        setTimeout(() => {
          console.log("updating tags", tags);
        }, 100);
      }
    }
  };

  const removeTag = async (id: string) => {
    const response = await fetch(`/app/api/ideas/${idea.id}`, {
      method: "PUT",
      body: JSON.stringify({ tag: id }),
    });

    if (response.ok) {
      console.log("tag removed");
      setTags(tags.filter((tag) => tag.id !== id));
    }
  };

  return (
    <>
      <div
        className={clsx(
          "flex flex-col justify-between gap-2 p-4 max-w-full aspect-square",
          "bg-[#cf4c67] text-white challenge-card aspect-square"
        )}
      >
        <header className="stack gap-2">
          <div className="flex items-center gap-2">
            <button
              className="p-0 flex items-center gap-2 add-tags"
              onClick={() => {
                setShowTags(true);
              }}
              key={`tags-${tags.length}`}
            >
              <Hash />{" "}
              {tags?.length > 0 ? (
                tags.map((tag) => (
                  <button
                    key={tag.id}
                    className="tag flex items-center gap-1"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      removeTag(tag.id);
                    }}
                  >
                    {tag.name}
                    <X size={16} className="relative top-[2px]" />
                  </button>
                ))
              ) : (
                <span>Add Tags</span>
              )}
            </button>
          </div>
          <h2 className={`text-xl font-bold`}>{idea.idea}</h2>
        </header>

        <div className="flex justify-between gap-2">
          <FormattedDate
            className={`opacity-50 text-white`}
            date={idea.createdAt}
          />
          <div className="flex items-center gap-2">
            <Link href={`/app/canvas/${idea.thinknadoId}?mode=canvas`}>
              <Eye color="white" />
            </Link>
          </div>
        </div>
      </div>
      <If condition={showTags}>
        <div className="fixed top-0 left-0 w-full h-full grid place-items-center">
          <div
            className="absolute top-0 left-0 w-full h-full bg-[#ffffff80] cursor-pointer"
            onClick={() => setShowTags(false)}
          ></div>
          <input
            type="text"
            onKeyDown={handleKeyDown}
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            className="max-w-full w-[600px] h-[80px] text-[2rem] bg-white p-2 rounded-thinknado relative z-10 border-2 border-pink px-4"
            autoFocus
          />
        </div>
      </If>
    </>
  );
}
