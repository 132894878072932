"use client";

import React, { useState } from "react";
import styles from "./ChallengeGrid.module.scss";
import clsx from "clsx";
import { ChallengeCard } from "@/components/components/ChallengeCard";
import { set } from "date-fns";
import { Button } from "@/components/actions/Button";
import { useChallenges } from "@/providers/Challenges";
import * as Toast from "@radix-ui/react-toast";
import If from "@/components/common/If";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import FormattedDate from "@/components/components/FormattedDate";
import { Eye, Plus } from "lucide-react";
import Link from "next/link";
import IdeaCard from "./IdeaCard";

/**
 *
 * ChallengeGrid
 *
 */
const ChallengeGrid = ({ fullPage = false, isLight = false, ...props }) => {
  const [mode, setMode] = useState("thinknados");
  const {
    challenges,
    removeChallenge,
    loadingChallenges,
    thinknados,
    ideas,
    setThinknados,
  } = useChallenges();
  const [isDeleted, setIsDeleted] = useState(false);
  // const [challengeList, setChallengeList] = useState(challenges);
  const onDelete = async (id = "") => {
    removeChallenge(id);
    setIsDeleted(true);
    setThinknados(thinknados.filter((t) => t.id !== id));
    // setChallenges(challenges.filter((c) => c.id !== id));
  };

  // console.log("thinknados", thinknados);
  // console.log("ideas", ideas);

  return (
    <>
      <header className="mt-24 mb-10">
        <If condition={fullPage}>
          <h1 className="text-4xl uppercase text-black max-w-full w-[600px] mx-auto block text-center">
            Your {mode === "thinknados" ? "Thinknados" : "Idea Portfolio"}
          </h1>
        </If>
      </header>
      <section
        className={clsx(
          styles["challenge-grid"],
          ` grid ${fullPage ? "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-gutter" : "container grid-cols-1"} gap-4`
        )}
        {...props}
      >
        {/* {challenges?.length > 0 &&
          challenges.map((challenge) => (
            <ChallengeCard
              onDelete={onDelete}
              key={challenge.id}
              challenge={challenge}
              isLight={isLight}
            />
          ))} */}
      </section>
      <If condition={fullPage}>
        <ToggleGroup
          type="single"
          value={mode}
          onValueChange={(value) => {
            console.log("value", value);
            setMode(value as "thinknados" | "ideas");
          }}
          className="w-max mx-auto bg-[#f6eee3] rounded-thinknado overflow-hidden mb-16"
        >
          <ToggleGroupItem
            className="data-[state=on]:bg-pink px-4 py-2"
            value="thinknados"
          >
            Thinknados
          </ToggleGroupItem>
          <ToggleGroupItem
            className="data-[state=on]:bg-pink px-4 py-2"
            value="ideas"
          >
            Idea Portfolio
          </ToggleGroupItem>
        </ToggleGroup>

        {loadingChallenges && (
          <div className="flex flex-col gap-4 items-center justify-center col-span-5">
            <div
              className={`text-xl text-center ${fullPage ? "text-black" : "text-white"}`}
            >
              Loading...
            </div>
          </div>
        )}

        {thinknados?.length === 0 && !fullPage && !loadingChallenges && (
          <div className="flex flex-col  gap-4">
            <p className="text-xl text-white">
              You have not Thinknadod yet...what are you waiting for?
            </p>
          </div>
        )}

        {thinknados?.length === 0 && fullPage && !loadingChallenges && (
          <div className=" col-span-5">
            <div className="max-w-full w-[600px] mx-auto flex flex-col gap-10">
              <p className="text-xl text-black text-left">
                Welcome to Thinknado, where your ideas will shine like diamonds
                and your imagination will dance like a wizard's spell! You’ve
                just stepped into a world where boring is banned, and creativity
                reigns supreme. Get ready to flip conventional thinking on its
                head, embrace chaos, and laugh in the face of the ordinary. Dive
                in, dare to be different, and let's turn your wildest dreams
                into reality. Remember, if your ideas are still boring, you owe
                us money—or a small goat. Welcome to the revolution!
              </p>
              <Button href="/app/canvas" className="w-1/2 mx-auto">
                Start a new challenge
              </Button>
            </div>
          </div>
        )}
        <div className="challenge-grid">
          <If condition={mode === "thinknados"}>
            <Link
              className={clsx(
                "flex flex-col justify-between gap-2 p-4 max-w-full aspect-square",
                "border-purple border-2 text-purple challenge-card aspect-square hover:bg-purple hover:text-white transition-all duration-200"
              )}
              href="/app/canvas"
            >
              <h2 className={`text-xl font-bold flex items-center gap-2`}>
                Start a New Session <Plus />
              </h2>
            </Link>
            {thinknados?.map((thinknado) => (
              <ChallengeCard
                onDelete={onDelete}
                key={thinknado.id}
                challenge={thinknado}
                isLight={fullPage}
                isThinknado
              />
            ))}
          </If>
          <If condition={mode === "ideas"}>
            <Link
              className={clsx(
                "flex flex-col justify-between gap-2 p-4 max-w-full aspect-square",
                "border-purple border-2 text-purple challenge-card aspect-square hover:bg-purple hover:text-white transition-all duration-200"
              )}
              href="/app/canvas"
            >
              <h2 className={`text-xl font-bold flex items-center gap-2`}>
                Start a New Session <Plus />
              </h2>
            </Link>
            {ideas?.map((idea) => <IdeaCard key={idea.id} idea={idea} />)}
          </If>
        </div>
      </If>
      <If condition={!fullPage}>
        <div className="stack gap-12 max-w-full mx-auto w-[600px]">
          <If condition={thinknados?.length > 0}>
            {thinknados.map((thinknado) => (
              <ChallengeCard
                onDelete={onDelete}
                key={thinknado.id}
                challenge={thinknado}
                isLight={fullPage}
                isThinknado
              />
            ))}
          </If>
        </div>
      </If>
      <Toast.Root
        className="bg-black text-white rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
        open={isDeleted}
        onOpenChange={setIsDeleted}
      >
        <Toast.Title className="[grid-area:_title] mb-[5px] font-bold text-slate12 text-[15px] uppercase text-white">
          Success
        </Toast.Title>
        <Toast.Description asChild>
          <p className="text-gray text-sm">Your session has been deleted.</p>
        </Toast.Description>
        <Toast.Action className="[grid-area:_action]" asChild altText="Close">
          <button className="inline-flex items-center justify-center rounded font-medium text-xs px-[10px] leading-none h-[25px] bg-pink text-white ">
            Close
          </button>
        </Toast.Action>
      </Toast.Root>
    </>
  );
};

export default ChallengeGrid;
