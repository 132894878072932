"use client";

import { use, useContext, useEffect } from "react";
import { useState } from "react";
import { createContext, ReactNode } from "react";

interface ChallengesContextType {
  challenges: any[];
  setChallenges: (challenges: any[]) => void;
  thinknados: any[];
  setThinknados: (thinknados: any[]) => void;
  addChallenge: (challenge: any) => void;
  removeChallenge: (id: string) => void;
  reloadChallenges: () => void;
  loadingChallenges: boolean;
  ideas: any[];
  setIdeas: (ideas: any[]) => void;
}

export const ChallengesContext = createContext<ChallengesContextType>({
  challenges: [],
  setChallenges: () => {},
  thinknados: [],
  setThinknados: () => {},
  addChallenge: () => {},
  removeChallenge: () => {},
  reloadChallenges: () => {},
  loadingChallenges: false,
  ideas: [],
  setIdeas: () => {},
});

export const ChallengesContextProvider = ChallengesContext.Provider;

interface ChallengesProviderProps {
  children: ReactNode;
  challenges?: any[];
}

export function ChallengesProvider({ children }: ChallengesProviderProps) {
  const [challenges, setChallenges] = useState([]);
  const [thinknados, setThinknados] = useState([]);
  const [ideas, setIdeas] = useState([]);
  const [loadingChallenges, setLoadingChallenges] = useState(true);

  const loadChallenges = async () => {
    setLoadingChallenges(true);
    const res = await fetch("/app/api/challenges");
    const data = await res.json();
    console.log("data", data);
    setChallenges(data?.challenges || []);
    setThinknados(data?.thinknados || []);
    setIdeas(data?.ideas || []);
    setLoadingChallenges(false);
  };

  useEffect(() => {
    loadChallenges();
  }, []);

  const addChallenge = (challenge) => {
    setChallenges([...challenges, challenge]);
  };

  const removeChallenge = (id) => {
    setChallenges(challenges.filter((c) => c.id !== id));
  };

  const reloadChallenges = () => {
    loadChallenges();
  };

  return (
    <ChallengesContextProvider
      value={{
        challenges,
        setChallenges,
        thinknados,
        setThinknados,
        addChallenge,
        removeChallenge,
        reloadChallenges,
        loadingChallenges,
        ideas,
        setIdeas,
      }}
    >
      {children}
    </ChallengesContextProvider>
  );
}

export function useChallenges() {
  const context = useContext(ChallengesContext);
  if (context === undefined) {
    throw new Error("useChallenges must be used within a ChallengesProvider");
  }
  return context;
}
